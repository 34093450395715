import { template as template_204066c099ef4ed6870c2a591bc937f2 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_204066c099ef4ed6870c2a591bc937f2(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
