import { template as template_75c2bb18b46b4f978dab1bc9b70fff77 } from "@ember/template-compiler";
const FKControlMenuContainer = template_75c2bb18b46b4f978dab1bc9b70fff77(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
